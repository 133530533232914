import React, { memo } from "react"
import isEqual from "lodash/isEqual"
import InformationWrapper from "../InformationWrapper"
import InformationRow from "../InformationRow"

import { getFullNameUser, ROUTES } from "@lesmills/gatsby-theme-common"

type Props = {|
  prismicData: Object,
  sectionStyles?: Object,
  user: Object,
  lang: string,
|}

const PersonalInfoSection = ({
  prismicData = {},
  sectionStyles = {},
  user,
  lang,
}: Props) => {
  const {
    personalInfoSection = {},
    name_label = {},
    email_address_label = {},
    password_label = {},
  } = prismicData

  const { section_title = {}, update_url = {} } =
    personalInfoSection.primary || {}

  return (
    <InformationWrapper
      title={section_title.text}
      updateLink={{
        label: update_url.text,
        url: ROUTES(lang).EDIT_PERSONAL_INFORMATION,
      }}
      classNames={sectionStyles}
    >
      <InformationRow
        label={name_label.text}
        value={getFullNameUser(user.givenName, user.familyName)}
        testId="personal-info-name"
      />
      <InformationRow
        label={email_address_label.text}
        value={user.email}
        testId="personal-info-email"
      />
      <InformationRow
        label={password_label.text}
        value="**************************"
        testId="personal-info-password"
      />
    </InformationWrapper>
  )
}

export default memo(PersonalInfoSection, (prevProps, nextProps) => {
  return isEqual(prevProps.user, nextProps.user)
})
